<template>
    <div class="layout-default" v-if="initialize">
        <div class="layout-box bg-white text-[#0d0c22] dark:border-l-indigo-950 dark:text-gray-300">
            <slot />
        </div>
        <Transition name="loading-fade">
            <div class="global-loading" v-if="store.$state.loading">
                <div>
                    <div class="box">
                        <div class="coin"></div>
                    </div>
                    <div class="mt-8 text-lg">{{ t('loading') }}</div>
                </div>
            </div>
        </Transition>
        <footer>
            <!-- <div class=" text-center pb-5">@UtureAI</div> -->
            <div class="pt-4 pb-6 text-center text-xs text-gray-300 bg-white dark:bg-slate-800" @click="enableDebugMode">Version: {{ store.$state.version }}</div>
        </footer>
    </div>
</template>
<script setup lang="ts">
import { IconArrowBarDown } from '@tabler/icons-vue';
import { throttle } from 'lodash-es';
const route = useRoute();
const router = useRouter();
const { t, locale, setLocale, setLocaleCookie } = useI18n();
const store = useGlobalStore();

if (store.$state.jwt) {
    if (store.$state.debug) console.log('set lang => ', store.$state.lang);

    setLocale(store.$state.lang);
}

const debugModeCount = ref(0);
function enableDebugMode() {
    debugModeCount.value++;
    console.log('debugModeCount => ', debugModeCount.value);
    if (debugModeCount.value >= 10) {
        if (store.$state.debug) {
            return;
        }
        const debugModeKey = prompt('Please enter debug mode key');
        if (debugModeKey === 'debuggg') {
            store.$state.debug = true;
            if (window.eruda) {
                window.eruda.init();
            } else {
                const script = document.createElement('script');
                script.src = '//cdn.jsdelivr.net/npm/eruda';
                document.body.appendChild(script);
                script.onload = () => {
                    window.eruda.init();
                };
            }
            $webapp().showAlert('Debug mode enabled');
        } else {
            debugModeCount.value = 0;
        }
    }
}

// useHead({
//     htmlAttrs: {
//         lang: locale.value,
//     },
// });

const initialize = ref(false);

const throttleInterval = 200; // 毫秒
const throttledHideKeyboard = throttle(() => {
    store.hideKeyboard();
}, throttleInterval);

async function verifyIsSafeBrowser() {
    const routerKey = route.query.wkey || '';
    console.log('store.$state.wkey => ', store.$state.wkey);
    if (!routerKey && !store.$state.wkey) {
        return false;
    }
    try {
        const res = await $api()('/bot/whatsapp/verify', {
            headers: {
                'x-whatsapp-auth': store.$state.wkey || routerKey.toString(),
            }
        });
        if (store.$state.debug) console.log('verifyIsSafeBrowser => ', res.data);
        if (res.success && res.data) {
            if (routerKey) {
                store.$state.wkey = routerKey.toString();
            }
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

async function getUserJwt() {
    if (store.$state.jwt) {
        return;
    }
    if (!store.$state.telegram.initData) {
        if (!await verifyIsSafeBrowser()) {
            router.replace('/ban?hasBackHistory=false');
            return;
        }
    }

    let user;
    try {
        user = await $api()('/bot/tg/info');
        if (store.$state.debug) console.log('user info => ', user);
    } catch (error: any) {
        throw new Error(error?.response?._data?.message || error.message);
    }

    if (store.$state.debug) console.log('user lang d => ', user.data.lang);
    if (user && user.data.lang) {
        if (user.data.lang === 'zh') {
            store.$state.lang = 'zh-CN';
        } else if (user.data.lang === 'en') {
            store.$state.lang = 'en-US';
        }
    } else {
        store.$state.lang = 'en-US';
    }

    if (store.$state.debug) console.log('set lang => ', store.$state.lang);
    setLocale(store.$state.lang);
    if (!user.success) {
        // router.replace('/user/sign-up?hasBackHistory=false');
        store.$state.loading = false;
        throw new Error(user.msg || user.message);
    }

    if (!user.data.jwtToken) {
        store.$state.loading = false;
        throw new Error('not logged in');
    }

    store.$state.jwt = user.data.jwtToken;
}

async function getUserInfo() {
    console.log('default layout getUserInfo');
    try {
        await getUserJwt();
    } catch (error: any) {
        if (store.$state.debug) console.log('get user jwt error => ', error.message);
        router.replace('/user/login?hasBackHistory=false');
        store.$state.loading = false;
        // $webapp().showAlert(t('home.getUserInfoError', { msg: error?.message }));
        return;
    }
}

onMounted(async () => {
    if (process.client) {
        if (!window.Telegram) {
            console.error('Telegram is not defined');
            router.replace('/ban?hasBackHistory=false');
            store.$state.loading = false;
            initialize.value = true;
            return;
        }

        // store.$state.isCardUser = true;
        if (route.query.isCardUser) {
            store.$state.isCardUser = true;
        }

        window.addEventListener('touchmove', throttledHideKeyboard);

        store.$state.initWindowHeight = window.innerHeight;
        if (store.$state.debug) console.log('initWindowHeight => ', store.$state.initWindowHeight);
        try {
            if (store.$state.debug) await window.eruda.init();
        } catch (error) {
            alert('eruda init error');
            console.log('eruda init error => ', error);
        }
        window.Telegram.WebApp.ready();
        const platform = window.Telegram.WebApp.platform;
        console.log('platform => ', platform);
        if (platform !== 'unknown') {
            window.Telegram.WebApp.MainButton.setParams({
                text: '关闭钱包',
                is_visible: false,
            }).onClick(() => {
                window.Telegram.WebApp.close();
            });
            // window.Telegram.WebApp.setHeaderColor('secondary_bg_color');
            window.Telegram.WebApp.setBackgroundColor('#ffffff');
            window.Telegram.WebApp.BackButton.onClick(() => {
                // $webapp().showAlert(`back to ${path}, ${window.location.pathname}, ${window.location.href}`)
                if (store.$state.debug) console.log('back evant => ', window.location.href);
                const router = useRouter();
                router.back();
            });
            if (store.$state.debug) console.log('theme =>', window.Telegram.WebApp.themeParams);
            window.Telegram.WebApp.setHeaderColor('secondary_bg_color');
            // window.Telegram.WebApp.expand(); // 展开
            const initData = window.Telegram.WebApp.initData;
            const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
            if (store.$state.debug) console.log('initData => ', initData);
            if (store.$state.debug) console.log('initDataUnsafe => ', initDataUnsafe);
            if (store.$state.debug) console.log('platform => ', platform);
            if (store.$state.debug) console.log('init jwt => ', store.$state.jwt);
            if (initDataUnsafe.hash) {
                store.$state.telegram.initData = initData;
                store.$state.telegram.initDataUnsafe = initDataUnsafe;
            }
        } else {
            const isSafeBrowser = await verifyIsSafeBrowser();
            console.log('isSafeBrowser => ', isSafeBrowser);
            if (!isSafeBrowser) {
                console.log('not safe browser');
                await router.replace('/ban?hasBackHistory=false');
                initialize.value = true;
                store.$state.loading = false;
                return;
            }
        }

        // 获取用户信息
        // await getUserInfo();
        initialize.value = true;
        store.$state.loading = false;
    }
});

watch(
    () => route.path,
    path => {
        if (process.client) {
            if (store.$state.debug) console.log('route path => ', route.fullPath, window.location.href);
            if (window.Telegram.WebApp.platform !== 'unknown') {
                if (path === '/' || route.query.hasBackHistory === 'false') {
                    window.Telegram.WebApp.BackButton.hide();
                } else {
                    window.Telegram.WebApp.BackButton.show();
                }
            }
        }
    }
);
</script>
<style lang="scss">
.layout-default {
    @apply flex min-h-screen flex-col mx-auto max-w-lg relative xl:shadow-xl xl:rounded-xl xl:overflow-hidden xl:my-10;

    .loading-fade-enter-active,
    .loading-fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .loading-fade-enter-from,
    .loading-fade-leave-to {
        opacity: 0;
    }
    .global-loading {
        @apply fixed left-0 top-0 z-50 flex h-full w-full touch-none items-center justify-center bg-white/50 backdrop-blur-md;
        .box {
            perspective: 99999px;
            @apply flex justify-center;
        }

        .coin {
            @apply h-16 w-16 rounded-full border-[10px] border-solid border-indigo-500;
            animation: spin 1.5s ease-in-out infinite;
        }

        @keyframes spin {
            to {
                transform: rotateY(540deg);
            }
        }
    }
}
</style>
